import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import DataGridHome from "../Components/DataGridHome";
import AppBarMain from "../Components/AppBarMain";
import DataGridReferral from "../Components/DataGridReferral";
import { useLocation, useNavigate } from "react-router-dom";
import Diversity3Icon from "@mui/icons-material/Diversity3";
import DashboardIcon from "@mui/icons-material/Dashboard";
import DataGridActivatedUsers from "../Components/DataGridActivatedUsers";
import DataGridWooCommerce from "../Components/DataGridWooCommerce";
import DataGridCampaignStatus from "../Components/DataGridCampaignStatus";
import PeopleIcon from "@mui/icons-material/People";
import StorefrontIcon from "@mui/icons-material/Storefront";
import DataGridShopify from "../Components/DataGridShopify";
import LocalGroceryStoreIcon from '@mui/icons-material/LocalGroceryStore';
import DataGridSMSConfig from "../Components/DataGridSMSConfig";
import DataGridWALogs from "../Components/DataGridWALogs";
import SmsIcon from '@mui/icons-material/Sms';

const drawerWidth = 240;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${drawerWidth}px`,
  ...(open && {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

export default function MainHome() {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  // const localdata = useSelector((state) => state.main.localdata);
  // const authtoken = useSelector(state=>state.main.auth_token);
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const { search } = useLocation();
  const [currentPath, setCurrentPath] = React.useState("Dashboard");

  React.useEffect(() => {
    switch (search) {
      case "?referrals":
        setCurrentPath("Referrals");
        break;
      case "?activatedUsers":
        setCurrentPath("Activated Users");
        break;
      case "?woocommerce":
        setCurrentPath("WooCommerce");
        break;
      case "?shopify":
        setCurrentPath("Shopify");
        break;
      case "?sms":
        setCurrentPath("SMS");
        break;
        case "?waLogs":
        setCurrentPath("WALogs");
        break;
        case "?campaignStatus":
        setCurrentPath("Campaign Status");
        break;
      default:
        setCurrentPath("Dashboard");
        break;
    }
  }, [search]);

  const getCurrentComponent = (pathUrl: string) => {
    console.log(search);
    switch (pathUrl.substring(1)) {
      case "referrals":
        return <DataGridReferral />;
      case "activatedUsers":
        return <DataGridActivatedUsers />;
      case "woocommerce":
        return <DataGridWooCommerce />;
      case "shopify":
        return <DataGridShopify />
      case "sms":
        return <DataGridSMSConfig />
      case "waLogs":
       return <DataGridWALogs/>
       case "campaignStatus":
        return <DataGridCampaignStatus />;
      default:
        return <DataGridHome />;
    }
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, ...(open && { display: "none" }) }}
          >
            <MenuIcon />
          </IconButton>
          {/* <Typography variant="h6" noWrap component="div">
            Persistent drawer
          </Typography> */}
          <AppBarMain />
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "ltr" ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          {["Dashboard", "Referrals", "Activated Users", "WooCommerce", "Shopify", "SMS","WALogs","Campaign Status"].map(
            (text, index) => (
              <DrawerItem key={index} text={text} currentPath={currentPath} />
            )
          )}
        </List>
      </Drawer>
      <Main open={open}>
        <DrawerHeader />
        {getCurrentComponent(search)}
      </Main>
    </Box>
  );
}

const DrawerItem = ({ text, currentPath }: any) => {
  const navigate = useNavigate();
  const handleOnClick = () => {
    if (text === "Referrals") navigate("/app?referrals", {});
    else if (text === "Activated Users") navigate("/app?activatedUsers", {});
    else if (text === "Dashboard") navigate("/app", {});
    else if (text === "WooCommerce") navigate("/app?woocommerce", {});
    else if (text === "WALogs") navigate("/app?waLogs", {});
    else if (text === "Campaign Status") navigate("/app?campaignStatus", {});
    else if (text === "Shopify") navigate("/app?shopify", {});
    else if (text === "SMS") navigate("/app/?sms", {});
  };

  const getIcon = (text: string) => {
    switch (text) {
      case "Referrals":
        return <Diversity3Icon />;
      case "Activated Users":
        return <PeopleIcon />;
      case "WooCommerce":
        return <StorefrontIcon />;
      case "Shopify":
        return <LocalGroceryStoreIcon />
      case "SMS":
        return <SmsIcon />
        case "WALogs":
          return <StorefrontIcon />;
          case "Campaign Status":
            return <StorefrontIcon />;
      default:
        return <DashboardIcon />;
    }
  };

  return (
    <ListItem
      disablePadding
      onClick={handleOnClick}
      sx={text !== currentPath ? { opacity: "75%" } : {}}
    >
      <ListItemButton>
        <ListItemIcon>{getIcon(text)}</ListItemIcon>
        <ListItemText
          primary={text}
          primaryTypographyProps={
            text === currentPath ? { fontWeight: 550 } : {}
          }
        />
      </ListItemButton>
    </ListItem>
  );
};
