import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import * as React from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  fetchShopifyData,
  fetchShopifyHooksData,
} from "../Services/index";
import { useState } from "react";
import { Box, Button, Modal } from "@mui/material";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  // width: 800,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: 2,
  p: 4,
};

export default function DataGridShopify() {
  const authtoken = localStorage.getItem("token");
  const [shopifyData, setshopifyData] = useState([]);
  const [pagination, setPagination] = useState({
    page: 0,
    limit: 10,
    totalRows: 1,
  });
  const [hooksPagination] = useState({
    page: 0,
    limit: 10,
    totalRows: 1,
  });
  const [search] = useState("");
  const [loading, setLoading] = useState(true);
  const [hooksLoading, setHooksLoading] = useState(true);
//   const [reconnectionLoading, setReconnectionLoading] = useState<
//     { storeName: string; loading: boolean }[]
//   >([]);
  const [showHooksTable, setShowHooksTable] = useState(false);
  const [hooksData, setHooksData] = useState([]);
  const [currentBasicKey, setCurrentBasicKey] = useState("");

  React.useEffect(() => {
    const getShopifyData = async () => {
      setLoading(true);
      fetchShopifyData(authtoken, pagination.page, pagination.limit, search)
        .then((response) => {
          setshopifyData(response.data.docs);
          setPagination({
            ...pagination,
            totalRows: response.data.total,
          });
        })
        .catch(() => {
          toast.error("Failed to fetch Shopify data");
        })
        .finally(() => {
          setLoading(false);
        });
    };

    getShopifyData();
  }, [search, authtoken, pagination.limit, pagination.page]);

  const getShopifyHooksData = async ({
    storeName,
    accessToken,
  }: {
    storeName: string;
    accessToken: string;
  }) => {
    try {
      setHooksLoading(true);
      const data = await fetchShopifyHooksData(
        storeName,
        accessToken,
        authtoken
      );
      if(data?.flag) { 
        setHooksData(data.data?.webhooks);
        console.log(data.data?.webhooks)
    //     setCurrentBasicKey(data.key);
    //     setReconnectionLoading(
    //         data.data.map((hook: any) => ({
    //         storeUrl: hook?._links?.self[0]?.href ?? "",
    //         loading: false,
    //         }))
    //   );
    }
      setHooksLoading(false);
    } catch (error) {
      setHooksLoading(false);
      setShowHooksTable(false);
      setHooksData([]);
      setCurrentBasicKey("");
    //   setReconnectionLoading([]);
      toast("Error fetching hooks");
    }
  };

//   const handleReconnectHook = async (selfUrl: string) => {
//     try {
//       setReconnectionLoading((prev) =>
//         prev.map((each) =>
//           each.storeUrl === selfUrl ? { ...each, loading: true } : each
//         )
//       );
//       const data = await activateWooCommerceHooks(selfUrl, currentBasicKey);
//       setHooksData((p) =>
//         p.map((hd) => ({
//           ...hd,
//           status: hd.id === data.id ? data.status : hd.status,
//         }))
//       );
//       setReconnectionLoading((prev) =>
//         prev.map((each) =>
//           each.storeUrl === selfUrl ? { ...each, loading: false } : each
//         )
//       );
//     } catch (error) {
//       console.log(error.message);
//       setReconnectionLoading((prev) =>
//         prev.map((each) =>
//           each.storeUrl === selfUrl ? { ...each, loading: false } : each
//         )
//       );
//       toast("Error reconnecting hook");
//     }
//   };

  const columns = [
    {
      field: "storeName",
      headerName: "Store Name",
      width: 140,
      hide: false,
      renderCell: (params: any) => {
        return params?.row?.storeName ?? "NA";
      },
    },
    {
      field: "shopUrl",
      headerName: "Shop Url",
      width: 140,
      hide: false,
      renderCell: (params: any) => {
        return params?.row?.shopUrl ?? "NA";
      },
    },
    {
      field: "accessToken",
      headerName: "Access Token",
      width: 120,
      hide: false,
      renderCell: (params: any) => {
        return params?.row?.accessToken ?? "NA";
      },
    },
    {
      field: "apiSecretKey",
      headerName: "API Secret Key",
      width: 120,
      hide: false,
      renderCell: (params: any) => {
        return params?.row?.apiSecretKey ?? "NA";
      },
    },
    {
      field: "orderCreateWebhook",
      headerName: "Order Create Webhook",
      width: 140,
      hide: false,
      renderCell: (params: any) => {
        return params?.row?.orderCreateWebhook ? "True" : "False";
      },
    },
    {
      field: "orderCancelWebhook",
      headerName: "Order Cancel Webhook",
      width: 140,
      hide: false,
      renderCell: (params: any) => {
        return params?.row?.orderCancelWebhook ? "True" : "False";
      },
    },
    {
      field: "orderFeedbackWebhook",
      headerName: "Order Feedback Webhook",
      width: 140,
      hide: false,
      renderCell: (params: any) => {
        return params?.row?.orderFeedbackWebhook ? "True" : "False";
      },
    },
    {
      field: "orderFullfillWebhook",
      headerName: "Order Fullfill Webhook",
      width: 140,
      hide: false,
      renderCell: (params: any) => {
        return params?.row?.orderFullfillWebhook ? "True" : "False";
      },
    },
    {
      field: "abandonCartWebhook",
      headerName: "Abondon Cart Webhook",
      width: 140,
      hide: false,
      renderCell: (params: any) => {
        return params?.row?.abandonCartWebhook ? "True" : "False";
      },
    },
    {
      field: "abandonCartWorkflow",
      headerName: "Abondon Cart Workflow",
      width: 140,
      hide: false,
      renderCell: (params: any) => {
        return params?.row?.abandonCartWorkflow ? "True" : "False";
      },
    },
    {
      field: "codWebhook",
      headerName: "COD Webhook",
      width: 140,
      hide: false,
      renderCell: (params: any) => {
        return params?.row?.codWebhook ? "True" : "False";
      },
    },
    {
      field: "orderCreateCOD",
      headerName: "order Create COD",
      width: 140,
      hide: false,
      renderCell: (params: any) => {
        return params?.row?.orderCreateCOD ? "True" : "False";
      },
    },
    {
      field: "orderCancelCOD",
      headerName: "order Cancel COD",
      width: 140,
      hide: false,
      renderCell: (params: any) => {
        return params?.row?.orderCancelCOD ? "True" : "False";
      },
    },
    {
      field: "orderFeedbackCOD",
      headerName: "order Feedback COD",
      width: 140,
      hide: false,
      renderCell: (params: any) => {
        return params?.row?.orderCancelCOD ? "True" : "False";
      },
    },
    {
      field: "orderFullfillCOD",
      headerName: "order Fullfill COD",
      width: 140,
      hide: false,
      renderCell: (params: any) => {
        return params?.row?.orderCancelCOD ? "True" : "False";
      },
    },
    {
      field: "Verify Connection",
      width: 130,
      renderCell: (params: any) => {
        return (
          <>
            {!params?.row?.webhookStatus && (
              <Button
                size="small"
                variant="contained"
                color="primary"
                onClick={() => {
                  setShowHooksTable(true);
                  getShopifyHooksData({
                    storeName: params?.row?.storeName,
                    accessToken: params?.row?.accessToken,
                  });
                }}
              >
                Verify
              </Button>
            )}
          </>
        );
      },
    },
  ];

  const hooksColumn = [
    {
      field: "api_version",
      headerName: "API Version",
      width: 140,
      hide: false,
      renderCell: (params: any) => {
        return params?.row?.api_version ?? "NA";
      },
    },
    {
      field: "created_at",
      headerName: "created_at",
      width: 140,
      hide: false,
      renderCell: (params: any) => {
        const date = new Date(params?.row?.created_at);
        return date.toDateString() ?? "NA"
      },
    },
    {
      field: "updated_at",
      headerName: "updated_at",
      width: 140,
      hide: false,
      renderCell: (params: any) => {
        const date = new Date(params?.row?.updated_at);
        return date.toDateString() ?? "NA"
      },
    },
    {
      field: "WebhookId",
      headerName: "Webhook ID",
      width: 120,
      hide: false,
      renderCell: (params: any) => {
        return params?.row?.id ?? "NA";
      },
    },
    {
      field: "topic",
      headerName: "Topic",
      width: 120,
      hide: false,
      renderCell: (params: any) => {
        return params?.row?.topic ?? "NA";
      },
    },
    {
      field: "delivery_url",
      headerName: "Webhook URL",
      width: 670,
      hide: false,
      renderCell: (params: any) => {
        return params?.row?.address ?? "NA";
      },
    },
    // {
    //   field: "Connection",
    //   width: 160,
    //   renderCell: (params: any) => {
    //     const selfUrl = params?.row?._links?.self[0]?.href ?? "";
    //     return (
    //       <Box paddingInline={1}>
    //         {params?.row?.status === "active" ? (
    //           <Button size="small" variant="contained" color="primary" disabled>
    //             Connected
    //           </Button>
    //         ) : (
    //         //   <Button
    //         //     size="small"
    //         //     variant="contained"
    //         //     color="primary"
    //         //     sx={{ display: "flex", gap: 0.5, alignItems: "center" }}
    //         //     onClick={() => {
    //         //     //   handleReconnectHook(selfUrl);
    //         //     }}
    //         //   >
    //         //     {reconnectionLoading.find((rl) => rl.storeName === selfUrl)
    //         //       .loading
    //         //       ? "Loading"
    //         //       : "Reconnect"}
    //         //     {reconnectionLoading.find((rl) => rl.storeName === selfUrl)
    //         //       .loading && <CircularProgress size={12} color="inherit" />}
    //         //   </Button>
    //         )}
    //       </Box>
    //     );
    //   },
    // },
  ];

  return (
    <div style={{ height: 550, width: "100%" }}>
      <DataGrid
        rows={shopifyData}
        columns={columns}
        getRowId={(row: any) => row._id}
        loading={loading}
        slots={{ toolbar: GridToolbar }}
        disableColumnFilter
        rowCount={pagination.totalRows}
        pageSizeOptions={[10, 20, 25, 50, 75, 100]}
        paginationMode="server"
        paginationModel={{
          page: pagination.page,
          pageSize: pagination.limit,
        }}
        onPaginationModelChange={(params) => {
          setPagination({
            ...pagination,
            page: params.page,
            limit: params.pageSize,
          });
        }}
        slotProps={{
          toolbar: {
            csvOptions: {
              allColumns: true,
              fields: [
                "userId",
                "shopUrl",
                "accessToken",
                "apiSecretKey",
                "storeName",
                "orderCreateWebhook",
                "orderCancelWebhook",
                "orderFeedbackWebhook",
                "orderFullfillWebhook",
                "abandonCartWebhook",
                "abandonCartWorkflow",
                "codWebhook",
                "orderCreateCOD",
                "orderCancelCOD",
                "orderFeedbackCOD",
                "orderFullfillCOD",
              ],
            },
          },
        }}
      />
      <Modal
        open={showHooksTable}
        onClose={() => {
          setShowHooksTable(false);
          setHooksData([]);
        }}
      >
        <Box sx={style}>
          <DataGrid
            style={{ minHeight: "320px", maxHeight: "720px" }}
            rows={hooksData}
            columns={hooksColumn}
            getRowId={(row: any) => row.id}
            loading={hooksLoading}
            // slots={{ toolbar: GridToolbar }}
            disableColumnFilter
            // checkboxSelection
            rowCount={hooksPagination.totalRows}
            pageSizeOptions={[10, 20, 25, 50, 75, 100]}
            paginationMode="server"
            paginationModel={{
              page: hooksPagination.page,
              pageSize: hooksPagination.limit,
            }}
            slotProps={{
              toolbar: {
                csvOptions: {
                  allColumns: true,
                  fields: [
                    "name",
                    "status",
                    "topic",
                    "resource",
                    "event",
                    "hooks",
                    "delivery_url",
                  ],
                },
              },
            }}
            onPaginationModelChange={(params) => {
              setPagination({
                ...pagination,
                page: params.page,
                limit: params.pageSize,
              });
            }}
          />
        </Box>
      </Modal>
      <ToastContainer />
    </div>
  );
}
