
import { useState, useEffect } from 'react';
import { Card, CardContent, Typography, Grid, Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material';
import { fetchlastWorkflowIniciatedTime } from "../Services/index";
import { toast, ToastContainer } from "react-toastify";
import { Modal, Box } from '@mui/material';
import "react-toastify/dist/ReactToastify.css";
import CloseIcon from '@mui/icons-material/Close';
import { DataGrid } from '@mui/x-data-grid';
import * as React from 'react';
import { Tooltip } from '@mui/material';
import CheerioButton from './cheerioButton'
import colors from '../utils/colors';
import { Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import  {getLocaltime} from'../utils/localdatetime'

const WorkFlowIniatedTimeStampDashboard = () => {
  const [lastWorkflowTime, setlastWorkflowTime] = useState()
  const [openModal, setOpenModal] = useState(false);
  const authtoken = localStorage.getItem("token");
  const [selectedDays, setSelectedDays] = useState(1); 
  const [pagination, setPagination] = useState({
    page: 0,
    limit: 5,
    totalRows: 1,
  });
  useEffect(() => {
    const fetchCampaignStatusAPI = async () => {
      try {
        const response = await fetchlastWorkflowIniciatedTime(authtoken);
        setlastWorkflowTime(getLocaltime(response?.response?.updatedAt))
        console.log("response?.response",response?.response?.updatedAt)
      } catch (error) {
        toast.error("Failed to fetch Incomplte Onboarding count");
      } 
    };

    fetchCampaignStatusAPI();
  }, [authtoken]);

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={3} marginLeft={-30} marginRight={-2}>
          <Card style={{ height: '120px', width: '265px' }}>
            <CardContent>
            <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  gap: '6px',
                  marginTop:'-2px'
                }}
              >
                <Typography variant="h8" color="black">
                  {lastWorkflowTime}
                </Typography>

              </div>
              <Typography
                variant="body1"
                style={{  display: 'flex', alignItems: 'center', cursor: 'pointer' , marginTop:'40px'}}
                onClick={() => setOpenModal(true)}
              >
                Last Workflow initiated Time
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <ToastContainer />
      </Grid>
    </>
  );
};

export { WorkFlowIniatedTimeStampDashboard };
// export function IncompleteOnboardingDashboardModel({setIncomplteOnboardingData,days}) {
//   const authtoken = localStorage.getItem("token");
//   const [rows, setRows] = useState([]);
//   const [loading, setLoading] = useState(false);
//   const [incomplteOnboardingcount,setIncomplteOnboardingcount] = useState(0);

//   const [pagination, setPagination] = useState({
//     page: 0,
//     limit: 5,
//     totalRows: 1,
//   });
//   const [deleteModalOpen, setDeleteModalOpen] = useState(false);
//   const [selectedUserId, setSelectedUserId] = useState(null);

//   useEffect(()=>{
//   })
  
//   const handleDelete = async (paramId) => {
  
//     try {
//       await deleteIncomplteOnboardingDetails(authtoken, paramId); // Replace with your API function
//       toast.success('user deleted successfully!');
//       setDeleteModalOpen(false)
//       IncomplteOnboardingDetails()
//     } catch (error) {
//       toast.error('Error deleting workflow.'); // Optional user feedback
//       console.error('Error deleting workflow:', error);
//     }
//   };
  

//   const style = {
//     position: 'absolute',
//     top: '50%',
//     left: '50%',
//     transform: 'translate(-50%, -50%)',
//     width: '700px',
//     bgcolor: 'background.paper',
//     borderRadius: 5,
//     boxShadow: 24,
//     pt: 2,
//     px: 4,
//     pb: 3,
//   };
//   const columns = [
//     {
//       field: 'Name',
//       headerName: 'Name',
//       width: 200,
//     },
//     {
//       field: 'date',
//       headerName: 'Date of Onboarding',
//       width: 200,
//     },
//     {
//       field: 'emailId',
//       headerName: 'Email',
//       width: 200,
//     },
//     {
//       field: 'userId',
//       headerName: 'User Id.',
//       width: 210,
//     },
//     {
//       field: 'phoneNo',
//       headerName: 'Phone No.',
//       width: 150,
//     },
//     // {
//     //   field: 'action',
//     //   headerName: 'Action',
//     //   width: 150,
//     //   renderCell: (params) => (
//     //      <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
             
//     //         <i
//     //           className="pi pi-copy"
//     //           style={{ fontSize: '1.2em', color: 'blue', cursor: 'pointer' }}
//     //           onClick={() => {
//     //             navigator.clipboard.writeText(params.row.emailId);
//     //             // alert(`Copied: ${params.row.emailId}`);
//     //             toast.success('email Copied to clipboard!');

//     //           }}
//     //         />
//     //         <Tooltip title="Delete user">
//     //         <div
//     //           onClick={() => {
//     //             setSelectedUserId(params.row.id);
//     //             setDeleteModalOpen(true);
//     //           }}
//     //         >
//     //           <FiTrash2 />
//     //         </div>
//     //       </Tooltip>

//     //       </div>
          
//     //   ),
//     // },
//   ];


//   const IncomplteOnboardingDetails = async (page, limit) => {
//     setLoading(true);
//     console.log("authtoken", authtoken)
//     try {
//       const response = await fetchIncomplteOnboardingDetails(authtoken, pagination.page + 1, pagination.limit , days);
//       if (response?.response.users) {
//         console.log(" ia m hereehvkjl/")
//         const campaigns = response.response.users.map((item, index) => ({
//           id: item?._id,
//           date: getLocaltime(item?.createdAt),
//           Name: item?.name || 'N/A',
//           emailId: item?.email || 'N/A',
//           phoneNo: item?.mobile || 'N/A',
//           userId:item?._id,

//         }));
//         console.log("response?.data?.total", response?.response?.totalCount)
//         setRows(campaigns);
//         // setIncomplteOnboardingData(response?.response?.totalCount)
//         setPagination({
//           ...pagination,
//           totalRows: response?.response?.totalCount ?? 0,
//         });
//       }
//     } catch (error) {
//       console.error("Error fetching campaigns:", error);
//     } finally {
//       setLoading(false);
//     }
//   };
//   useEffect(() => {
//     IncomplteOnboardingDetails(pagination.page, pagination.limit);
//   }, [pagination.page, pagination.limit,days]); // Trigger fetch when page or limit changes

//   return (
//     <>
//     <Box sx={{ height: 400, width: '100%' }}>
//       <DataGrid
//         rows={rows}
//         columns={columns}
//         loading={loading}
//         paginationMode="server"
//         paginationModel={{
//           page: pagination.page,
//           pageSize: pagination.limit,
//         }}
//         rowCount={pagination.totalRows}
//         pageSizeOptions={[5, 20, 25, 50, 75]}
//         sx={SxStyling}
//         disableColumnMenu
//         disableColumnFilter
//         onPaginationModelChange={(params) => {
//           setPagination({
//             ...pagination,
//             page: params.page,
//             limit: params.pageSize,
//           });
//         }}
        
//       />
//     </Box>
//     <Modal
//     open={deleteModalOpen}
//     onClose={() => setDeleteModalOpen(false)}
//     aria-labelledby="child-modal-title"
//     aria-describedby="child-modal-description"
//   >
//     <Box sx={{ ...style, width: 550 }}>
//       <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
//         <h2 id="child-modal-title">Delete User?</h2>
//         <CloseIcon style={{ cursor: 'pointer' }} onClick={() => setDeleteModalOpen(false)} />
//       </div>
//       <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px', gap: '20px' }}>
//         {/* <button onClick={() => setDeleteModalOpen(false)}>Close</button> */}

//         <CheerioButton
//                 borderStyle={{
//                   borderColor: colors.black,
//                   width: '100%',
//                   height: '6%',
//                   marginBottom: '5px',
//                 }}
//                 textStyle={{ fontSize: 14, fontWeight: 600, color: colors.black }}
//                 btnText={'Close'}
//                 backColor={'transparent'}
//                 onclick={() => setDeleteModalOpen(false)}
//               />
//         {/* <button
//           style={{ backgroundColor: 'red', color: 'white' }}
//           onClick={handleDeleteUser}
//         >
//           Delete
//         </button> */}
//         <CheerioButton
//                 borderStyle={{
//                   borderColor: colors.black,
//                   width: '100%',
//                   height: '6%',
//                   marginBottom: '5px',
//                 }}
//                 textStyle={{ fontSize: 14, fontWeight: 600, color: colors.white }}
//                 btnText={'Delete'}
//                 backColor={colors.red49}
//                 onclick={() => {
//                   handleDelete(selectedUserId); // Use selectedUserId from state
//                 }}
//               />
//       </div>
//     </Box>
//   </Modal>
//  </>
//   );
// }