import { useState, useEffect } from 'react';
import { Card, CardContent, Typography, Grid, Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material';
import { NewAccountDetails } from "../Services/index";
import { toast, ToastContainer } from "react-toastify";
import { Modal, Box } from '@mui/material';
import "react-toastify/dist/ReactToastify.css";
import CloseIcon from '@mui/icons-material/Close';
import { DataGrid } from '@mui/x-data-grid';
import * as React from 'react';
import { Tooltip } from '@mui/material';
import CheerioButton from './cheerioButton'
import colors from '../utils/colors';
import { getLocaltime } from '../utils/localdatetime'
import SearchBar from "./InputSearch"
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Select, MenuItem, FormControl, InputLabel } from '@mui/material';

export const SxStyling = {
  '&>.MuiDataGrid-main': {
    '&>.MuiDataGrid-columnHeaders': {
      borderBottom: 'none',
      background: '#F0F2F2',
    },
    '.MuiDataGrid-columnHeaderTitle': {
      fontWeight: '600',
    },
    '& .MuiDataGrid-columnHeader, .MuiDataGrid-cell': {
      borderRight: `1px solid #B3B3B3`,
    },
    '& div  >.MuiDataGrid-cell': {
      borderBottom: 'none',
      borderRight: '1px solid #B3B3B3',
      fontWeight: '400',
    },
  },
  '.MuiTablePagination-displayedRows, .MuiTablePagination-selectLabel': {
    'margin-top': '1em',
    'margin-bottom': '1em',
  },
};
const NewAccountCreatedDashboard = () => {
  const [newAccountCreateddata, SetnewAccountCreateddata] = useState();
  const [openModal, setOpenModal] = useState(false);
  const authtoken = localStorage.getItem("token");
  const [selectedDays, setSelectedDays] = useState(1);
  const [search, setSearch] = useState("");
  const [pagination, setPagination] = useState({
    page: 0,
    limit: 5,
    totalRows: 1,
  })

  useEffect(() => {
    const fetchCampaignStatusAPI = async () => {
      try {
        const response = await NewAccountDetails(authtoken, pagination.page + 1, pagination.limit, selectedDays, search);
        SetnewAccountCreateddata(response?.response?.totalCount)
      } catch (error) {
        toast.error("Failed to fetch Incomplte Onboarding count");
      }
    };

    fetchCampaignStatusAPI();
  }, [authtoken, selectedDays]);

  const handleDaysChange = async (event) => {
    const days = event.target.value;
    setSelectedDays(days);
  };


  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={4} marginLeft={-40}>
          <Card style={{ height: '120px', width: '265px' }}>
            <CardContent>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  gap: '10px',
                }}
              >
                <Typography variant="h6" color="black">
                  {newAccountCreateddata}
                </Typography>
                <FormControl
                sx={{
                  minWidth: 120,
                  height: 'auto',
                  borderRadius: 2,
                  padding: 1,
                }}
              >
                <InputLabel
                  id="days-selector-label"
                  sx={{
                    marginLeft: 1,
                    fontSize: '0.9rem',
                    fontWeight: 'bold',
                  }}
                >
                  Select Days
                </InputLabel>
                <Select
                  labelId="days-selector-label"
                  value={selectedDays}
                  onChange={handleDaysChange}
                  sx={{
                    height: 40, 
                    paddingLeft: 1, 
                    fontSize: '1rem', 
                  }}
                >
                  <MenuItem value={1}>1 Day</MenuItem>
                  <MenuItem value={7}>7 Days</MenuItem>
                  <MenuItem value={30}>30 Days</MenuItem>
                </Select>
              </FormControl>

              </div>
              <Typography
                variant="body1"
                style={{ marginTop: '8px', display: 'flex', alignItems: 'center', cursor: 'pointer', marginTop: '8px' }}
                onClick={() => setOpenModal(true)}
              >
                NewAccount Created
                {openModal ? (
                  <KeyboardArrowDownIcon
                    style={{ marginLeft: '8px', fontSize: '1.2rem' }}
                  />
                ) : (
                  <KeyboardArrowRightIcon
                    style={{ marginLeft: '8px', fontSize: '1.2rem' }}
                  />
                )}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <ToastContainer />
      </Grid>
      <Dialog
        open={openModal}
        onClose={() => setOpenModal(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle
        style={{ top:-2,marginBottom:'-6px' }}
        >
          NewAccount Created Details
          <IconButton
            style={{ position: 'absolute', right: 8, top: 6 }}
            onClick={() => setOpenModal(false)}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <NewAccountDetailsModel days={selectedDays} />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default NewAccountCreatedDashboard;
export function NewAccountDetailsModel({ days }) {
  const authtoken = localStorage.getItem("token");
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");

  const [pagination, setPagination] = useState({
    page: 0,
    limit: 5,
    totalRows: 1,
  });
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '700px',
    bgcolor: 'background.paper',
    borderRadius: 5,
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };
  const columns = [
    {
      field: 'Name',
      headerName: 'Name',
      width: 200,
    },
    {
      field: 'date',
      headerName: 'Date of Onboarding',
      width: 200,
    },
    {
      field: 'emailId',
      headerName: 'Email',
      width: 200,
    },
    {
      field: 'userId',
      headerName: 'User Id.',
      width: 210,
    },
    {
      field: 'phoneNo',
      headerName: 'Phone No.',
      width: 150,
    },
  ];


  const IncomplteOnboardingDetails = async (page, limit) => {
    setLoading(true);
    console.log("authtoken", authtoken)
    try {
      const response = await NewAccountDetails(authtoken, pagination.page + 1, pagination.limit, days, search);
      if (response?.response.users) {
        console.log(" ia m hereehvkjl/")
        const data = response.response.users.map((item, index) => ({
          id: item?._id,
          date: getLocaltime(item?.createdAt),
          Name: item?.name || 'N/A',
          emailId: item?.email || 'N/A',
          phoneNo: item?.mobile || 'N/A',
          userId: item?._id,
        }));
        console.log("response?.data?.total", response?.response?.totalCount)
        setRows(data);
        setPagination({
          ...pagination,
          totalRows: response?.response?.totalCount ?? 0,
        });
      }
    } catch (error) {
      console.error("Error fetching campaigns:", error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    IncomplteOnboardingDetails(pagination.page, pagination.limit);
  }, [pagination.page, pagination.limit, days, search]);

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  return (
    <>
      <Box sx={{ height: 400, width: '100%' }}>
        <SearchBar
          value={search}
          onChange={handleSearchChange}
        />
        <DataGrid
          rows={rows}
          columns={columns}
          loading={loading}
          paginationMode="server"
          paginationModel={{
            page: pagination.page,
            pageSize: pagination.limit,
          }}
          rowCount={pagination.totalRows}
          pageSizeOptions={[5, 20, 25, 50, 75]}
          sx={SxStyling}
          disableColumnMenu
          disableColumnFilter
          onPaginationModelChange={(params) => {
            setPagination({
              ...pagination,
              page: params.page,
              limit: params.pageSize,
            });
          }}

        />
      </Box>
      <Modal
        open={deleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box sx={{ ...style, width: 550 }}>
          <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
            <h2 id="child-modal-title">Delete User?</h2>
            <CloseIcon style={{ cursor: 'pointer' }} onClick={() => setDeleteModalOpen(false)} />
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px', gap: '20px' }}>

            <CheerioButton
              borderStyle={{
                borderColor: colors.black,
                width: '100%',
                height: '6%',
                marginBottom: '5px',
              }}
              textStyle={{ fontSize: 14, fontWeight: 600, color: colors.black }}
              btnText={'Close'}
              backColor={'transparent'}
              onclick={() => setDeleteModalOpen(false)}
            />
          </div>
        </Box>
      </Modal>
    </>
  );
}