import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import * as React from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { fetchReferralData } from "../Services/index";
import { useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import InputBase from "@mui/material/InputBase";
import { styled, alpha } from "@mui/material/styles";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  border: "1px solid #ccc",
  maxWidth: "400px",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  margin: theme.spacing(2),

  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

export default function DataGridReferral() {
  const authtoken = localStorage.getItem("token");
  const [referralData, setReferralData] = useState([]);
  // const [pagination, setPagination] = useState({
  //   page: 0,
  //   limit: 10,
  //   totalRows: 1,
  // });
  const [search, setSearch] = useState("");
  // const [sortModel, setSortModel] = React.useState<GridSortModel>([
  //   { field: "name", sort: "asc" },
  // ]);

  const [loading, setLoading] = useState(true);

  const columns = [
    {
      field: "referrerName",
      headerName: "Referrer Name",
      width: 140,
      hide: false,
      renderCell: (params: any) => {
        return params?.row?.referrerName ?? "NA";
      },
    },
    {
      field: "name",
      headerName: "Name",
      width: 120,
      hide: false,
      renderCell: (params: any) => {
        return params?.row?.name ?? "NA";
      },
    },
    {
      field: "companyName",
      headerName: "Company Name",
      width: 180,
      hide: false,
      renderCell: (params: any) => {
        return params?.row?.companyName ?? "NA";
      },
    },
    {
      field: "companyEmail",
      headerName: "Company Email",
      width: 200,
      hide: false,
      renderCell: (params: any) => {
        return params?.row?.companyEmail ?? "NA";
      },
    },
    {
      field: "companyMobile",
      headerName: "Company Mobile",
      width: 200,
      hide: false,
      renderCell: (params: any) => {
        return params?.row?.companyMobile ?? "NA";
      },
    },
    {
      field: "companyWebsite",
      headerName: "Company Website",
      width: 200,
      hide: false,
      renderCell: (params: any) => {
        return params?.row?.companyWebsite ?? "NA";
      },
    },
  ];

  React.useEffect(() => {
    const getReferralData = async () => {
      setLoading(true);
      fetchReferralData(authtoken, search)
        .then((response) => {
          setReferralData(response.data);
        })
        .catch((error) => {
          console.error("Failed to fetch agents data:", error);
          toast.error("Failed to fetch agents data");
        })
        .finally(() => {
          setLoading(false);
        });
    };

    getReferralData();
  }, [search, authtoken]);

  return (
    <div style={{ height: 550, width: "100%" }}>
      <Search>
        <SearchIconWrapper>
          <SearchIcon />
        </SearchIconWrapper>
        <StyledInputBase
          placeholder="Search…"
          inputProps={{ "aria-label": "search" }}
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
      </Search>
      <DataGrid
        rows={referralData}
        columns={columns}
        getRowId={(row: any) => row._id}
        loading={loading}
        slots={{ toolbar: GridToolbar }}
        disableColumnFilter
        // rowCount={pagination.totalRows}
        // checkboxSelection
        // pageSizeOptions={[10, 20, 25, 50, 75, 100]}
        // paginationMode="server"
        // paginationModel={{
        //   page: pagination.page,
        //   pageSize: pagination.limit,
        // }}
        // initialState={{
        //   sorting: {
        //     sortModel: [{ field: "name", sort: "desc" }],
        //   },
        // }}
        // sortModel={sortModel}
        // onSortModelChange={(newSortModel) => updateSorted(newSortModel)}
        slotProps={{
          toolbar: {
            csvOptions: {
              allColumns: true,
              fields: [
                "referrerName",
                "name",
                "companyName",
                "companyMobile",
                "companyEmail",
                "companyWebsite",
              ],
            },
          },
        }}
        // onPaginationModelChange={(params) => {
        //   setPagination({
        //     ...pagination,
        //     page: params.page,
        //     limit: params.pageSize,
        //   });
        // }}
      />
      <ToastContainer />
    </div>
  );
}
