import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import * as React from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  activateWooCommerceHooks,
  fetchWooCommerceData,
  fetchWooCommerceHooksData,
} from "../Services/index";
import { useState } from "react";
import { Box, Button, CircularProgress, Modal } from "@mui/material";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  // width: 800,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: 2,
  p: 4,
};

export default function DataGridWooCommerce() {
  const authtoken = localStorage.getItem("token");
  const [wooCommerceData, setWooCommerceData] = useState([]);
  const [pagination, setPagination] = useState({
    page: 0,
    limit: 10,
    totalRows: 1,
  });
  const [hooksPagination] = useState({
    page: 0,
    limit: 10,
    totalRows: 1,
  });
  const [search] = useState("");
  const [loading, setLoading] = useState(true);
  const [hooksLoading, setHooksLoading] = useState(true);
  const [reconnectionLoading, setReconnectionLoading] = useState<
    { storeUrl: string; loading: boolean }[]
  >([]);
  const [showHooksTable, setShowHooksTable] = useState(false);
  const [hooksData, setHooksData] = useState([]);
  const [currentBasicKey, setCurrentBasicKey] = useState("");

  React.useEffect(() => {
    const getWooCommerceData = async () => {
      setLoading(true);
      fetchWooCommerceData(authtoken, pagination.page, pagination.limit, search)
        .then((response) => {
          setWooCommerceData(response.data.docs);
          setPagination({
            ...pagination,
            totalRows: response.data.total,
          });
        })
        .catch(() => {
          toast.error("Failed to fetch woocommerce data");
        })
        .finally(() => {
          setLoading(false);
        });
    };

    getWooCommerceData();
  }, [search, authtoken, pagination.limit, pagination.page]);

  const getWooCommerceHooksData = async ({
    storeUrl,
    accessToken,
    apiSecretKey,
  }: {
    storeUrl: string;
    accessToken: string;
    apiSecretKey: string;
  }) => {
    try {
      setHooksLoading(true);
      const data = await fetchWooCommerceHooksData(
        storeUrl,
        accessToken,
        apiSecretKey
      );
      setHooksData(data.data);
      setCurrentBasicKey(data.key);
      setReconnectionLoading(
        data.data.map((hook: any) => ({
          storeUrl: hook?._links?.self[0]?.href ?? "",
          loading: false,
        }))
      );
      setHooksLoading(false);
    } catch (error) {
      setHooksLoading(false);
      setShowHooksTable(false);
      setHooksData([]);
      setCurrentBasicKey("");
      setReconnectionLoading([]);
      toast("Error fetching hooks");
    }
  };

  const handleReconnectHook = async (selfUrl: string) => {
    try {
      setReconnectionLoading((prev) =>
        prev.map((each) =>
          each.storeUrl === selfUrl ? { ...each, loading: true } : each
        )
      );
      const data = await activateWooCommerceHooks(selfUrl, currentBasicKey);
      setHooksData((p) =>
        p.map((hd) => ({
          ...hd,
          status: hd.id === data.id ? data.status : hd.status,
        }))
      );
      setReconnectionLoading((prev) =>
        prev.map((each) =>
          each.storeUrl === selfUrl ? { ...each, loading: false } : each
        )
      );
    } catch (error) {
      console.log(error.message);
      setReconnectionLoading((prev) =>
        prev.map((each) =>
          each.storeUrl === selfUrl ? { ...each, loading: false } : each
        )
      );
      toast("Error reconnecting hook");
    }
  };

  const columns = [
    {
      field: "storeName",
      headerName: "Store Name",
      width: 140,
      hide: false,
      renderCell: (params: any) => {
        return params?.row?.storeName ?? "NA";
      },
    },
    {
      field: "shopUrl",
      headerName: "Shop Url",
      width: 140,
      hide: false,
      renderCell: (params: any) => {
        return params?.row?.shopUrl ?? "NA";
      },
    },
    {
      field: "accessToken",
      headerName: "Access Token",
      width: 120,
      hide: false,
      renderCell: (params: any) => {
        return params?.row?.accessToken ?? "NA";
      },
    },
    {
      field: "apiSecretKey",
      headerName: "API Secret Key",
      width: 120,
      hide: false,
      renderCell: (params: any) => {
        return params?.row?.apiSecretKey ?? "NA";
      },
    },
    {
      field: "orderCreateWebhook",
      headerName: "Order Create Webhook",
      width: 140,
      hide: false,
      renderCell: (params: any) => {
        return params?.row?.orderCreateWebhook ? "True" : "False";
      },
    },
    {
      field: "orderCancelWebhook",
      headerName: "Order Cancel Webhook",
      width: 140,
      hide: false,
      renderCell: (params: any) => {
        return params?.row?.orderCancelWebhook ? "True" : "False";
      },
    },
    {
      field: "orderFeedbackWebhook",
      headerName: "Order Feedback Webhook",
      width: 140,
      hide: false,
      renderCell: (params: any) => {
        return params?.row?.orderFeedbackWebhook ? "True" : "False";
      },
    },
    {
      field: "orderFullfillWebhook",
      headerName: "Order Fullfill Webhook",
      width: 140,
      hide: false,
      renderCell: (params: any) => {
        return params?.row?.orderFullfillWebhook ? "True" : "False";
      },
    },
    {
      field: "abandonCartWebhook",
      headerName: "Abandon Cart Webhook",
      width: 140,
      hide: false,
      renderCell: (params: any) => {
        return params?.row?.abandonCartWebhook ? "True" : "False";
      },
    },
    {
      field: "Verify Connection",
      width: 130,
      renderCell: (params: any) => {
        return (
          <>
            {!params?.row?.webhookStatus && (
              <Button
                size="small"
                variant="contained"
                color="primary"
                onClick={() => {
                  setShowHooksTable(true);
                  getWooCommerceHooksData({
                    storeUrl: params?.row?.shopUrl,
                    accessToken: params?.row?.accessToken,
                    apiSecretKey: params?.row?.apiSecretKey,
                  });
                }}
              >
                Verify
              </Button>
            )}
          </>
        );
      },
    },
  ];

  const hooksColumn = [
    {
      field: "name",
      headerName: "Name",
      width: 140,
      hide: false,
      renderCell: (params: any) => {
        return params?.row?.name ?? "NA";
      },
    },
    {
      field: "status",
      headerName: "Status",
      width: 140,
      hide: false,
      renderCell: (params: any) => {
        return params?.row?.status ?? "NA";
      },
    },
    {
      field: "topic",
      headerName: "Topic",
      width: 120,
      hide: false,
      renderCell: (params: any) => {
        return params?.row?.topic ?? "NA";
      },
    },
    {
      field: "resource",
      headerName: "Resource",
      width: 120,
      hide: false,
      renderCell: (params: any) => {
        return params?.row?.resource ?? "NA";
      },
    },
    {
      field: "event",
      headerName: "Event",
      width: 140,
      hide: false,
      renderCell: (params: any) => {
        return params?.row?.event ?? "NA";
      },
    },
    {
      field: "hooks",
      headerName: "Hooks",
      width: 140,
      hide: false,
      renderCell: (params: any) => {
        return params?.row?.hooks?.join(", ") ?? "NA";
      },
    },
    {
      field: "delivery_url",
      headerName: "Delivery URL",
      width: 180,
      hide: false,
      renderCell: (params: any) => {
        return params?.row?.delivery_url ?? "NA";
      },
    },
    {
      field: "Connection",
      width: 160,
      renderCell: (params: any) => {
        const selfUrl = params?.row?._links?.self[0]?.href ?? "";
        return (
          <Box sx={{paddingInline:1}}>
            {params?.row?.status === "active" ? (
              <Button size="small" variant="contained" color="primary" disabled>
                Connected
              </Button>
            ) : (
              <Button
                size="small"
                variant="contained"
                color="primary"
                sx={{ display: "flex", gap: 0.5, alignItems: "center" }}
                onClick={() => {
                  handleReconnectHook(selfUrl);
                }}
              >
                {reconnectionLoading.find((rl) => rl.storeUrl === selfUrl)
                  .loading
                  ? "Loading"
                  : "Reconnect"}
                {reconnectionLoading.find((rl) => rl.storeUrl === selfUrl)
                  .loading && <CircularProgress size={12} color="inherit" />}
              </Button>
            )}
          </Box>
        );
      },
    },
  ];

  return (
    <div style={{ height: 550, width: "100%" }}>
      <DataGrid
        rows={wooCommerceData}
        columns={columns}
        getRowId={(row: any) => row._id}
        loading={loading}
        slots={{ toolbar: GridToolbar }}
        disableColumnFilter
        rowCount={pagination.totalRows}
        pageSizeOptions={[10, 20, 25, 50, 75, 100]}
        paginationMode="server"
        paginationModel={{
          page: pagination.page,
          pageSize: pagination.limit,
        }}
        onPaginationModelChange={(params) => {
          setPagination({
            ...pagination,
            page: params.page,
            limit: params.pageSize,
          });
        }}
        slotProps={{
          toolbar: {
            csvOptions: {
              allColumns: true,
              fields: [
                "userId",
                "shopUrl",
                "accessToken",
                "apiSecretKey",
                "storeName",
                "orderCreateWebhook",
                "orderCancelWebhook",
                "orderFeedbackWebhook",
                "orderFullfillWebhook",
                "abandonCartWebhook",
              ],
            },
          },
        }}
      />
      <Modal
        open={showHooksTable}
        onClose={() => {
          setShowHooksTable(false);
          setHooksData([]);
        }}
      >
        <Box sx={style}>
          <DataGrid
            style={{ minHeight: "320px", maxHeight: "720px" }}
            rows={hooksData}
            columns={hooksColumn}
            getRowId={(row: any) => row.id}
            loading={hooksLoading}
            // slots={{ toolbar: GridToolbar }}
            disableColumnFilter
            // checkboxSelection
            rowCount={hooksPagination.totalRows}
            pageSizeOptions={[10, 20, 25, 50, 75, 100]}
            paginationMode="server"
            paginationModel={{
              page: hooksPagination.page,
              pageSize: hooksPagination.limit,
            }}
            slotProps={{
              toolbar: {
                csvOptions: {
                  allColumns: true,
                  fields: [
                    "name",
                    "status",
                    "topic",
                    "resource",
                    "event",
                    "hooks",
                    "delivery_url",
                  ],
                },
              },
            }}
            onPaginationModelChange={(params) => {
              setPagination({
                ...pagination,
                page: params.page,
                limit: params.pageSize,
              });
            }}
          />
        </Box>
      </Modal>
      <ToastContainer />
    </div>
  );
}
