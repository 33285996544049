import { toast } from "react-toastify";
import { axiosInstance } from "../Config/axiosConfig";
import { type } from "../Config/apiTypeConstants";
import { endpoint } from "../Config/apiEndPointConstants";
import { Buffer } from "buffer";
import { GridFilterItem } from "@mui/x-data-grid";

//Authentication token api
export const loginADMIN = (data: any) => {
  return axiosInstance.post(type.V2_CUSTOMERSUCCESS + endpoint.LOGIN, data);
};

//Register APIS
export const registerADMIN = (data: any) => {
  return axiosInstance.post(type.V2_CUSTOMERSUCCESS + endpoint.REGISTER, data);
};

//Authentication token api
export const getCustomerData = async (
  token: string,
  page: number,
  limit: number,
  search: string
): Promise<any | undefined> => {
  return axiosInstance.get(
    type.V2_CUSTOMERSUCCESS +
      endpoint.GETCUTOMERDATA +
      `?page=${page}&limit=${limit}&search=${search}`,
    {
      headers: {
        "x-access-token": token,
      },
    }
  );
};

export const sortedCustomerData = async (
  token: string,
  page: number,
  limit: number,
  search: string,
  sortBy: "desc" | "asc",
  sortField: string,
  onlyAppsumoUsers: boolean,
  onlyWebhookSubscribedUsers: boolean,
  onlyFreeTrialUsers: boolean,
  filters: GridFilterItem[]
): Promise<any | undefined> => {
  return axiosInstance.post(
    type.V2_CUSTOMERSUCCESS +
      endpoint.SORTEDCUTOMERDATA +
      `?page=${page}&limit=${limit}&appsumoUsers=${onlyAppsumoUsers}&subscribedUsers=${onlyWebhookSubscribedUsers}&freeTrialUsers=${onlyFreeTrialUsers}&search=${search}&sortBy=${sortBy}&sortField=${sortField}`,
    { data: JSON.stringify(filters) },
    {
      headers: {
        "x-access-token": token,
        "Content-Type": "application/json",
      },
    }
  );
};

//Authentication token api
export const updateSingleUser = async (
  data: any,
  token: string
): Promise<any | undefined> => {
  return axiosInstance
    .put(type.V2_CUSTOMERSUCCESS + endpoint.UPDATEPREMIUM, data, {
      headers: {
        "x-access-token": token,
      },
    })
    .then(function (response) {
      return response.data;
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      toast.error(err?.response?.data?.message);
      console.log("err in userSpecific report api", err);
      return null;
    });
};

// send userSpecific report as mail (mail csv) API
export const mailUserSpecificCsv = (data: any, token: string) => {
  try {
    return axiosInstance
      .post(type.V2_CUSTOMERSUCCESS + endpoint.LOGIN, data, {
        headers: {
          "x-access-token": token,
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        toast.error(err);
        console.log("err in userSpecific report api", err);
        return null;
      });
  } catch (err) {}
};

export const manualConnection = async (
  data: any,
  token: string
): Promise<any | undefined> => {
  try {
    return axiosInstance
      .post(type.AISENSY + endpoint.CREATEAISENSYUSER, data, {
        headers: {
          "x-access-token": token,
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err.response.data;
      });
  } catch (err) {}
};

export const fetchAgentsData = async (
  token: string,
  userSpecificId: string
): Promise<any> => {
  return axiosInstance
    .post(
      type.V2_CUSTOMERSUCCESS + endpoint.FETCHALLAGENTS,
      {
        userSpecificId: userSpecificId,
      },
      {
        headers: {
          "x-access-token": token,
        },
      }
    )
    .then((response) => {
      // console.log("Response Data", response.data);
      return response.data;
    })
    .catch((err) => {
      toast.error(err?.response?.data?.message);
      console.log("Error fetching agent data:", err);
      return null;
    });
};

export const updateAgentStatus = async (
  token: string,
  agentId: string,
  status: number
): Promise<any> => {
  return axiosInstance
    .post(
      type.V2_CUSTOMERSUCCESS + endpoint.UPDATEAGENTSTATUS,
      {
        agentId: agentId,
        status: status,
      },
      {
        headers: {
          "x-access-token": token,
        },
      }
    )
    .then((response) => {
      // console.log("Response Data", response.data);
      return response.data;
    })
    .catch((err) => {
      toast.error(err?.response?.data?.message);
      console.log("Error fetching agent data:", err);
      return null;
    });
};

export const fetchReferralData = async (
  token: string,
  search?: string
): Promise<any> => {
  let url = type.V2_CUSTOMERSUCCESS + endpoint.GETREFERRALDATA;
  if (search) {
    url += `?search=${search}`;
  }

  return axiosInstance
    .get(url, {
      headers: {
        "x-access-token": token,
      },
    })
    .then((response) => {
      // console.log("Response Data", response.data);
      return response.data;
    })
    .catch((err) => {
      toast.error(err?.response?.data?.message);
      console.log("Error fetching agent data:", err);
      return null;
    });
};

export const fetchActivatedUsers = async (
  token: string,
  page: number,
  limit: number,
  search: string
): Promise<any> => {
  let url =
    type.V2_CUSTOMERSUCCESS +
    endpoint.ACTVATEDUSERS +
    `?page=${page}&limit=${limit}&search=${search}`;

  return axiosInstance
    .get(url, {
      headers: {
        "x-access-token": token,
      },
    })
    .then((response) => {
      // console.log("Response Data", response.data);
      return response.data;
    })
    .catch((err) => {
      toast.error(err?.response?.data?.message);
      console.log("Error fetching agent data:", err);
      return null;
    });
};

export const fetchWooCommerceData = async (
  token: string,
  page: number,
  limit: number,
  search: string
): Promise<any> => {
  let url =
    type.V2_CUSTOMERSUCCESS +
    endpoint.GETWOOCOMMERCECONFIGDATA +
    `?page=${page}&limit=${limit}&search=${search}`;

  return axiosInstance
    .get(url, {
      headers: {
        "x-access-token": token,
      },
    })
    .then((response) => {
      // console.log("Response Data", response.data);
      return response.data;
    })
    .catch((err) => {
      toast.error(err?.response?.data?.message);
      // console.log("Error fetching agent data:", err);
      return null;
    });
};

export const fetchWooCommerceHooksData = async (
  storeUrl: string,
  accessToken: string,
  apiSecretKey: string
): Promise<any> => {
  try {
    const encodedValueAuthKey = getBasicAuthToken(accessToken, apiSecretKey);
    const webhookUrl = `https://${storeUrl}/wp-json/wc/v3/webhooks/`;

    const resp = await fetch(webhookUrl, {
      method: "GET",
      headers: {
        Authorization: `Basic ${encodedValueAuthKey}`,
        "Content-Type": "application/json",
      },
    });

    if (!resp.ok) {
      throw new Error("Response fields for some reason.");
    }

    let data;
    const contentType = resp.headers.get("content-type");
    if (contentType && contentType.includes("application/json")) {
      data = await resp.json();
    } else {
      throw new Error("Response is not in JSON format.");
    }

    return { data, key: encodedValueAuthKey };
  } catch (error) {
    throw new Error(error);
  }
};

const getBasicAuthToken = (key: string, secret: string) => {
  return Buffer.from(`${key}:${secret}`).toString("base64");
};

export const activateWooCommerceHooks = async (
  selfUrl: string,
  basicAuthKey: string
): Promise<any> => {
  try {
    const resp = await fetch(selfUrl, {
      method: "POST",
      headers: {
        Authorization: `Basic ${basicAuthKey}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ status: "active" }),
    });

    if (!resp.ok) {
      throw new Error("Response fields for some reason.");
    }

    let data;
    const contentType = resp.headers.get("content-type");
    if (contentType && contentType.includes("application/json")) {
      data = await resp.json();
    } else {
      throw new Error("Response is not in JSON format.");
    }

    return data;
  } catch (error) {
    throw new Error(error);
  }
};

export const fetchShopifyData = async (
  token: string,
  page: number,
  limit: number,
  search: string
): Promise<any> => {
  let url =
    type.V2_CUSTOMERSUCCESS +
    endpoint.GetSHOPIFYDATA +
    `?page=${page}&limit=${limit}&search=${search}`;

  return axiosInstance
    .get(url, {
      headers: {
        "x-access-token": token,
      },
    })
    .then((response) => {
      // console.log("Response Data", response.data);
      return response?.data;
    })
    .catch((err) => {
      toast.error(err?.response?.data?.message);
      // console.log("Error fetching agent data:", err);
      return null;
    });
};

export const fetchShopifyHooksData = async (
  storeName: string,
  accessToken: string,
  token: string
): Promise<any> => {
  let url =
    type.V2_CUSTOMERSUCCESS +
    endpoint.GetSHOPIFYWEBHOOKS;

    

  return axiosInstance
    .post(url, {storeName, accessToken}, {
      headers: {
        "x-access-token": token,
      },
    })
    .then((response) => {
         return response?.data;
    })
    .catch((err) => {
      toast.error(err?.response?.data?.message);
      // console.log("Error fetching agent data:", err);
      return null;
    });
};


export const fetchSMSData = async (
  token: string,
  page: number,
  limit: number,
  search: string
): Promise<any> => {
  let url =
    type.V2_CUSTOMERSUCCESS +
    endpoint.GetSMSDATA +
    `?page=${page}&limit=${limit}&search=${search}`;

  return axiosInstance
    .get(url, {
      headers: {
        "x-access-token": token,
      },
    })
    .then((response) => {
      // console.log("Response Data", response.data);
      return response?.data;
    })
    .catch((err) => {
      toast.error(err?.response?.data?.message);
      // console.log("Error fetching agent data:", err);
      return null;
    });
};

export const resetWebhook = async (
  token: string,
  userId:string,
): Promise<any | undefined> => {
  return axiosInstance
    .patch(type.V2_CUSTOMERSUCCESS + endpoint.REMOVECONFIG, {userId},{
      headers: {
        "x-access-token": token,
      },
    })
    .then(function (response) {
      return response.data;
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      toast.error(err?.response?.data?.message);
      console.log("err in reset webhook api", err);
      return null;
    });
};

export const updateWalletBalance = async (
  token:string,
  userId: string,
balance:number,
): Promise<any | undefined> => {
  return axiosInstance
    .patch(type.V2_CUSTOMERSUCCESS + endpoint.WALLETBALANCE, {userId,balance},{
      headers: {
        "x-access-token": token,
      },
    })
    .then(function (response) {
      return response.data;
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      toast.error(err?.response?.data?.message);
      console.log("err in update wallet balance api", err);
      return null;
    });
};

export const updateWalletCurrency = async (
  token:string,
  userId: string,
currency:string,
): Promise<any | undefined> => {
  return axiosInstance
    .patch(type.V2_CUSTOMERSUCCESS + endpoint.CURRENCY, {userId,currency},{
      headers: {
        "x-access-token": token,
      },
    })
    .then(function (response) {
      return response.data;
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      toast.error(err?.response?.data?.message);
      console.log("err in update wallet currency api", err);
      return null;
    });
};

export const getWALogsRecord = async (
  token: string,
  page: number,
  limit: number,
  search: string
): Promise<any> => {
  let url =
    type.V2_CUSTOMERSUCCESS +
    endpoint.WALOGS +
    `?page=${page}&limit=${limit}&search=${search}`;

  return axiosInstance
    .get(url, {
      headers: {
        "x-access-token": token,
      },
    })
    .then((response) => {
      // console.log("Response Data", response.data);
      return response?.data;
    })
    .catch((err) => {
      toast.error(err?.response?.data?.message);
      // console.log("Error fetching agent data:", err);
      return null;
    });
};

export const fetchCampaignStatus = async (
  token: string,
): Promise<any> => {
  let url =
    type.V2_CUSTOMERSUCCESS +
    endpoint.CAMPAIGNSTATUS ;

  return axiosInstance
    .get(url, {
      headers: {
        "x-access-token": token,
      },
    })
    .then((response) => {
      // console.log("Response Data", response.data);
      return response.data;
    })
    .catch((err) => {
      toast.error(err?.response?.data?.message);
      // console.log("Error fetching agent data:", err);
      return null;
    });
};
export const fetchShopifyMessagesCount= async (
  token: string,
  days  : string
): Promise<any> => {
  let url =
    type.V2_CUSTOMERSUCCESS +
    endpoint.SHOPIFYMESSAGESCOUNT + `?days=${days}`;

  return axiosInstance
    .get(url, {
      headers: {
        "x-access-token": token,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      toast.error(err?.response?.data?.message);
      return null;
    });
};
export const fetchwoocommerceMessagesCount= async (
  token: string,
  days  : string
): Promise<any> => {
  let url =
    type.V2_CUSTOMERSUCCESS +
    endpoint.WOOCOMMERCEMESSAGESCOUNT + `?days=${days}`;

  return axiosInstance
    .get(url, {
      headers: {
        "x-access-token": token,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      toast.error(err?.response?.data?.message);
      return null;
    });
};
export const fetchActiveCampaignDetails = async (
  token: string,
  page : string,
  limit : string ,
  search :string
): Promise<any> => {
  let url =
    type.V2_CUSTOMERSUCCESS +
    endpoint.ACTIVECAMPAIGNDETAILS +
        `?page=${page}&limit=${limit}&search=${search}` ;

  return axiosInstance
    .get(url, {
      headers: {
        "x-access-token": token,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      toast.error(err?.response?.data?.message);
      return null;
    });
};

export const stopCampaignAPI = async (
  user_id: string,
  campaign_id: string,
  token: string
): Promise<any> => {
  let url =
    type.V2_CUSTOMERSUCCESS +
    endpoint.STOPCAMPAIGN;
  return axiosInstance
    .patch(url, {user_id, campaign_id}, {
      headers: {
        "x-access-token": token,
      },
    })
    .then((response) => {
         return response?.data;
    })
    .catch((err) => {
      toast.error(err?.response?.data?.message);
      return null;
    });
};

export const fetchlastWorkflowIniciatedTime = async (
  token : string,
): Promise<any> => {
  let url =
    `${type.V2_CUSTOMERSUCCESS}${endpoint.LASTWORKFLOWINICIATEDTIMESTAMP}`; ;

  return axiosInstance
    .get(url, {
      headers: {
        "x-access-token": token,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      toast.error(err?.response?.data?.message);
      return null;
    });
};

export const NewAccountDetails = async (
  token:string,
  page:string,
  limit:string,
  days:string,
  search: string
):Promise<any>=>{
    let url =
    `${type.V2_CUSTOMERSUCCESS}${endpoint.NEWACCOUNTDETAILS}?page=${page}&limit=${limit}&days=${days}&search=${search}`; 
  return axiosInstance
  .get(url, {
      headers: {
        "x-access-token": token,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      toast.error(err?.response?.data?.message);
      return null;
    });
}