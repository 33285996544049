import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import * as React from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
getWALogsRecord
} from "../Services/index";
import { useState } from "react";
import { Box, Button, CircularProgress, Modal } from "@mui/material";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  // width: 800,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: 2,
  p: 4,
};

export default function DataGridWALogs() {
  const authtoken = localStorage.getItem("token");
  const [waLogsData, setwaLogsData] = useState([]);
  const [pagination, setPagination] = useState({
    page: 0,
    limit: 10,
    totalRows: 1,
  });
  const [hooksPagination] = useState({
    page: 0,
    limit: 10,
    totalRows: 1,
  });
  const [search] = useState("");
  const [loading, setLoading] = useState(true);
  const [hooksLoading, setHooksLoading] = useState(true);
  const [reconnectionLoading, setReconnectionLoading] = useState<
    { storeUrl: string; loading: boolean }[]
  >([]);
  const [showHooksTable, setShowHooksTable] = useState(false);
  const [hooksData, setHooksData] = useState([]);
  const [currentBasicKey, setCurrentBasicKey] = useState("");

  React.useEffect(() => {
    const getWALogsRecordAPI = async () => {
      setLoading(true);
      getWALogsRecord(authtoken, pagination.page, pagination.limit, search)
        .then((response) => {
            setwaLogsData(response.data);
          setPagination({
            ...pagination,
            totalRows: response.data.total ?? 100000,
          });
        })
        .catch(() => {
          toast.error("Failed to fetch woocommerce data");
        })
        .finally(() => {
          setLoading(false);
        });
    };

    getWALogsRecordAPI();
  }, [search, authtoken, pagination.limit, pagination.page]);


  const columns = [
    {
      field: "_id",
      headerName: "User id",
      width: 250,
      hide: false,
      renderCell: (params: any) => {
        return params?.row?._id ?? "NA";
      },
    },
    {
      field: "logs",
      headerName: "WA Logs",
      width: 1000,
      hide: false,
      renderCell: (params: any) => {
        return params?.row?.logs ?? "NA";
      },
    },
    {
      field: "createdAt",
      headerName: "Created At",
      width: 200,
      hide: false,
      renderCell: (params: any) => {
        return params?.row?.createdAt ?? "NA";
      },
    },
    {
      field: "updatedAt",
      headerName: "Updated At",
      width: 200,
      hide: false,
      renderCell: (params: any) => {
        return params?.row?.updatedAt ?? "NA";
      },
    },
  ];

  return (
    <div style={{ height: 550, width: "100%" }}>
      <DataGrid
        rows={waLogsData}
        columns={columns}
        getRowId={(row: any) => row._id}
        loading={loading}
        slots={{ toolbar: GridToolbar }}
        disableColumnFilter
        rowCount={pagination.totalRows}
        pageSizeOptions={[10, 20, 25, 50, 75, 100]}
        paginationMode="server"
        paginationModel={{
          page: pagination.page,
          pageSize: pagination.limit,
        }}
        onPaginationModelChange={(params) => {
          setPagination({
            ...pagination,
            page: params.page,
            limit: params.pageSize,
          });
        }}
        slotProps={{
          toolbar: {
            csvOptions: {
              allColumns: true,
              fields: [
              "_id",
              "logs",
              "createdAt",
              "updatedAt",
              ],
            },
          },
        }}
      />
    </div>
  );
}
