import { useState, useEffect } from 'react';
import { Card, CardContent, Typography, Grid, CircularProgress } from '@mui/material';
import { fetchCampaignStatus, fetchwoocommerceMessagesCount } from "../Services/index";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as React from 'react';
import { Select, MenuItem, FormControl, InputLabel } from '@mui/material';

const WoocommerceDashboard = () => {
  const [WoocommercemessageData, setWoocommercemessageData] = useState()
  const authtoken = localStorage.getItem("token");
  const [selectedDays, setSelectedDays] = useState(1);
  useEffect(() => {
    const fetchwoocommerceMessagesCountAPI = async () => {
      try {
        const response = await fetchwoocommerceMessagesCount(authtoken, selectedDays);
        setWoocommercemessageData(response?.response)
      } catch (error) {
        toast.error("Failed to fetch Incomplte Onboarding count");
      }
    };
    fetchwoocommerceMessagesCountAPI();
  }, [authtoken, selectedDays]);
  const handleDaysChange = async (event) => {
    const days = event.target.value;
    setSelectedDays(days);

  };
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={6} md={4} marginLeft={-20}>
        <Card style={{ height: '120px', width: '265px' }}>
          <CardContent>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                gap: '10px',
              }}
            >
              <Typography variant="h6" color="black">
                {WoocommercemessageData}
              </Typography>
              <FormControl
                sx={{
                  minWidth: 120, 
                  height: 'auto',
                  borderRadius: 2,
                  padding: 1, 
                }}
              >
                <InputLabel
                  id="days-selector-label"
                  sx={{
                    marginLeft: 1, 
                    fontSize: '0.9rem', 
                    fontWeight: 'bold', 
                  }}
                >
                  Select Days
                </InputLabel>
                <Select
                  labelId="days-selector-label"
                  value={selectedDays}
                  onChange={handleDaysChange}
                  sx={{
                    height: 40, 
                    paddingLeft: 1, 
                    fontSize: '1rem', 
                  }}
                >
                  <MenuItem value={1}>1 Day</MenuItem>
                  <MenuItem value={7}>7 Days</MenuItem>
                  <MenuItem value={30}>30 Days</MenuItem>
                </Select>
              </FormControl>
            </div>

            <Typography variant="body1" style={{ marginTop: '8px' }}>
              Woocom. Messages
            </Typography>
          </CardContent>
        </Card>
      </Grid>
      <ToastContainer />
    </Grid>
  );
};

export default WoocommerceDashboard;