import { useState, useEffect } from 'react';
import { Card, CardContent, Typography, Grid, CircularProgress } from '@mui/material';
import { fetchCampaignStatus } from "../Services/index";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as React from 'react';

const DataGridCampaignStatus = () => {
  const [campaignStatusData, setCampaignStatusData] = useState<number | undefined>();
  const [loading, setLoading] = useState(true);
  const authtoken = localStorage.getItem("token");

  useEffect(() => {
    const fetchCampaignStatusAPI = async () => {
      setLoading(true);
      try {
        const response = await fetchCampaignStatus(authtoken);
        setCampaignStatusData(response?.data);
      } catch (error) {
        toast.error("Failed to fetch Campaign Status data");
      } finally {
        setLoading(false);
      }
    };

    fetchCampaignStatusAPI();
  }, [authtoken]);

  if (loading) {
    return (
      <Grid container justifyContent="center" alignItems="center" style={{ height: '100vh' }}>
        <CircularProgress />
      </Grid>
    );
  }

  return (
    <Grid container spacing={3}>
      {campaignStatusData !== undefined && (
        <Grid item xs={12} sm={6} md={4}>
          <Card>
            <CardContent>
              <Typography variant="h6" color="black">
                Campaign Status
              </Typography> 
              <Typography variant="h6" style={{ 
                marginTop: '16px',
                color: campaignStatusData > 0 ? '#00BF13' : '#F0E68C',
                borderRadius: '8px',
              }}>
                {campaignStatusData > 0 ? 'Running' : 'Free'}
              </Typography>
              <Typography variant="body1" style={{ marginTop: '8px' }}>
                Number of Running Campaigns: {campaignStatusData}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      )}
      <ToastContainer />
    </Grid>
  );
};

export default DataGridCampaignStatus;