import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import * as React from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { fetchActivatedUsers } from "../Services/index";
import { useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import InputBase from "@mui/material/InputBase";
import { styled, alpha } from "@mui/material/styles";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  border: "1px solid #ccc",
  maxWidth: "400px",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  margin: theme.spacing(2),

  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

export default function DataGridActivatedUsers() {
  const authtoken = localStorage.getItem("token");
  const [activatedUsersData, setActivatedUsersData] = useState([]);
  const [pagination, setPagination] = useState({
    page: 0,
    limit: 10,
    totalRows: 1,
  });
  const [search, setSearch] = useState("");
  // const [sortModel, setSortModel] = React.useState<GridSortModel>([
  //   { field: "name", sort: "asc" },
  // ]);

  const [loading, setLoading] = useState(true);
  console.log("activatedUsersData", activatedUsersData);
  const [filteredData, setFilteredData] = useState([]);

  const columns = [
    {
      field: "name",
      headerName: "Name",
      width: 200,
      hide: false,
      renderCell: (params: any) => {
        return params?.row?.name ?? "NA";
      },
    },
    {
      field: "email",
      headerName: "Email",
      width: 220,
      hide: false,
      renderCell: (params: any) => {
        return params?.row?.email ?? "NA";
      },
    },
    {
      field: "companyName",
      headerName: "Company Name",
      width: 180,
      hide: false,
      renderCell: (params: any) => {
        return params?.row?.companyName ?? "NA";
      },
    },
    {
      field: "createdAt",
      headerName: "Created At",
      width: 180,
      hide: false,
      renderCell: (params: any) => {
        const date = new Date(params?.row?.createdAt);
        return date.toLocaleString() ?? "NA";
      },
    },
    {
      field: "activationEmail",
      headerName: "Activation Email",
      width: 220,
      hide: false,
      renderCell: (params: any) => {
        return params?.row?.activationEmail ?? "NA";
      },
    },
    {
      field: "mobile",
      headerName: "Mobile",
      width: 200,
      hide: false,
      renderCell: (params: any) => {
        return params?.row?.mobile ?? "NA";
      },
    },
    {
      field: "action",
      headerName: "Status",
      width: 100,
      hide: false,
      renderCell: (params: any) => {
        return params?.row?.action ?? "NA";
      },
    },
    {
      field: "plan_id",
      headerName: "Plan Id",
      width: 200,
      hide: false,
      renderCell: (params: any) => {
        return params?.row?.plan_id ?? "NA";
      },
    },
    {
      field: "invoice_id",
      headerName: "Invoice Item UUID",
      width: 300,
      hide: false,
      renderCell: (params: any) => {
        return params?.row?.invoice_id ?? "NA";
      },
    },
  ];

  React.useEffect(() => {
    const fetchActivatedUsersData = async () => {
      setLoading(true);
      fetchActivatedUsers(authtoken, pagination.page, pagination.limit, search)
        .then((response) => {
          const formattedData = response.data.map((user: any) => ({
            ...user,
            name: user?.userDetails?.name ?? "NA",
                email: user?.email ?? "NA",
                companyName: user?.userDetails?.company_name ?? "NA",
                activationEmail: user?.logs?.activation_email ?? "NA",
                mobile: user?.userDetails?.mobile ?? "NA",
                action: user?.logs?.action ?? "NA",
                plan_id: user?.logs?.plan_id ?? "NA",
                invoice_id: user?.logs?.invoice_item_uuid ?? "NA",
              }));
              console.log("Response Data", response.data.length);
              setPagination({
                ...pagination,
                totalRows: search ? response.data.length : response?.data[0]?.totalSize ?? 0,
              });
              setActivatedUsersData(formattedData);
              setFilteredData(formattedData);
        })
        .catch((error) => {
          console.error("Failed to fetch agents data:", error);
          toast.error("Failed to fetch agents data");
        })
        .finally(() => {
          setLoading(false);
        });
    };

    fetchActivatedUsersData();
  }, [authtoken, pagination.page, pagination.limit, search]);

  // const handleSearch = (e: any) => {
  //   const value = e.target.value;
  //   setSearch(value);

  //   const filtered = activatedUsersData.filter((user) => {
  //     return (
  //       user.name.toLowerCase().includes(value.toLowerCase()) ||
  //       user.email.toLowerCase().includes(value.toLowerCase()) ||
  //       user.companyName.toLowerCase().includes(value.toLowerCase()) ||
  //       user.activationEmail.toLowerCase().includes(value.toLowerCase()) ||
  //       user.mobile.toLowerCase().includes(value.toLowerCase()) ||
  //       user.action.toLowerCase().includes(value.toLowerCase()) ||
  //       user.plan_id.toLowerCase().includes(value.toLowerCase()) ||
  //       user.invoice_id.toLowerCase().includes(value.toLowerCase())
  //     );
  //   });

  //   setFilteredData(filtered);
  // };

  return (
    <div style={{ height: 550, width: "100%" }}>
      <Search>
        <SearchIconWrapper>
          <SearchIcon />
        </SearchIconWrapper>
        <StyledInputBase
          placeholder="Search…"
          inputProps={{ "aria-label": "search" }}
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
      </Search>
      <DataGrid
        rows={filteredData}
        columns={columns}
        getRowId={(row: any) => row._id}
        loading={loading}
        slots={{ toolbar: GridToolbar }}
        disableColumnFilter
        rowCount={pagination.totalRows}
        // checkboxSelection
        pageSizeOptions={[10, 20, 25, 50, 75, 100]}
        paginationMode="server"
        paginationModel={{
          page: pagination.page,
          pageSize: pagination.limit,
        }}
        // initialState={{
        //   sorting: {
        //     sortModel: [{ field: "name", sort: "desc" }],
        //   },
        // }}
        // sortModel={sortModel}
        // onSortModelChange={(newSortModel) => updateSorted(newSortModel)}
        slotProps={{
          toolbar: {
            csvOptions: {
              allColumns: true,
              fields: [
                "name",
                "email",
                "companyName",
                "activationEmail",
                "mobile",
                "action",
                "plan_id",
                "invoice_id",
                "createdAt"
              ],
            },
          },
        }}
        onPaginationModelChange={(params) => {
          setPagination({
            ...pagination,
            page: params.page,
            limit: params.pageSize,
          });
        }}
      />
      <ToastContainer />
    </div>
  );
}
