import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import { fetchActiveCampaignDetails, stopCampaignAPI } from "../Services/index";
import { toast, ToastContainer } from "react-toastify";
import { getLocaltime } from '../utils/localdatetime'
import TextField from '@mui/material/TextField';
import SearchIcon from "@mui/icons-material/Search";
import InputBase from "@mui/material/InputBase";
import { styled, alpha } from "@mui/material/styles";
import SearchBar from "./InputSearch"
import StopCircleIcon from '@mui/icons-material/StopCircle';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
export const SxStyling = {
  '&>.MuiDataGrid-main': {
    '&>.MuiDataGrid-columnHeaders': {
      borderBottom: 'none',
      background: '#F0F2F2',
    },
    '.MuiDataGrid-columnHeaderTitle': {
      fontWeight: '600',
    },
    '& .MuiDataGrid-columnHeader, .MuiDataGrid-cell': {
      borderRight: `1px solid #B3B3B3`,
    },
    '& div  >.MuiDataGrid-cell': {
      borderBottom: 'none',
      borderRight: '1px solid #B3B3B3',
      fontWeight: '400',
    },
  },
  '.MuiTablePagination-displayedRows, .MuiTablePagination-selectLabel': {
    'margin-top': '1em',
    'margin-bottom': '1em',
  },
};

export default function ActiveCamapaign() {
  const authtoken = localStorage.getItem("token");
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [pagination, setPagination] = useState({
    page: 0,
    limit: 5,
    totalRows: 1,
  });

  const columns = [
    {
      field: 'campaignname',
      headerName: 'Campaign Name',
      width: 200,
    },
    {
      field: 'date',
      headerName: 'Date of Onboarding',
      width: 200,
    },
    {
      field: 'userName',
      headerName: 'User Name',
      width: 150,
    },
    {
      field: 'emailId',
      headerName: 'Email ID',
      width: 200,
    },
    {
      field: 'userId',
      headerName: 'User Id.',
      width: 215,
    },
    {
      field: 'phoneNo',
      headerName: 'Phone No.',
      width: 150,
    },
    {
      field: 'action',
      headerName: 'Action',
      width: 150,
      renderCell: (params) => (
        <div style={{ display: 'flex', gap: '15px', alignItems: 'center' }}>
          <StopCircleIcon
            style={{
              fontSize: '1.3em',
              color: 'red',
              cursor: 'pointer',
            }}
            onClick={() => handleStopCampaign(params.row.id, params.row.userId)}
          />
          <ContentCopyIcon
            style={{
              fontSize: '1.3em',
              color: 'blue',
              cursor: 'pointer',
            }}
            onClick={() => {
              navigator.clipboard.writeText(params.row.emailId);
              toast.success('Email copied to clipboard!');
            }}
          />
        </div>
      ),
    }
  ];

  const handleStopCampaign = async (userId, campaignId) => {
    try {
      const response = await stopCampaignAPI(campaignId, userId, authtoken);
      console.log("Campaign stopped successfully:", response);
      fetchCampaigns(pagination.page, pagination.limit, search);
    } catch (error) {
      console.error("Error stopping campaign:", error);
    }
  };

  const fetchCampaigns = async () => {
    setLoading(true);
    console.log("authtoken", authtoken)
    try {
      const response = await fetchActiveCampaignDetails(authtoken, pagination.page + 1, pagination.limit, search);
      if (response?.data?.docs) {
        const campaigns = response.data.docs.map((item, index) => ({
          id: item._id,
          campaignname: item.announcement.template_name,
          date: getLocaltime(item.announcement.date),
          userName: item.user_details?.name || 'N/A',
          emailId: item.user_details?.email || 'N/A',
          phoneNo: item.user_details?.mobile || 'N/A',
          status: item.status,
          userId: item.user_details?._id || null,
        }));
        console.log("response?.data?.total", response?.data?.total)
        setRows(campaigns);
        setPagination({
          ...pagination,
          totalRows: response?.data?.total ?? 0,
        });
      }
    } catch (error) {
      console.error("Error fetching campaigns:", error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchCampaigns(pagination.page, pagination.limit);
  }, [pagination.page, pagination.limit, search]);

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  return (
    <Box sx={{ height: 400, width: '100%' }}>
      <SearchBar
        value={search}
        onChange={handleSearchChange}
      />
      <DataGrid
        rows={rows}
        columns={columns}
        loading={loading}
        paginationMode="server"
        paginationModel={{
          page: pagination.page,
          pageSize: pagination.limit,
        }}
        rowCount={pagination.totalRows}
        pageSizeOptions={[5, 20, 25, 50, 75]}
        sx={SxStyling}
        disableColumnMenu
        disableColumnFilter
        onPaginationModelChange={(params) => {
          setPagination({
            ...pagination,
            page: params.page,
            limit: params.pageSize,
          });
        }}
      />
    </Box>
  );
}
