import React from 'react';
import { useState, useEffect } from 'react';
import { Card, CardContent, Typography, Grid, Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ActiveCamapaign from './ActiveCamapaign';
import { fetchCampaignStatus } from "../Services/index";
import { toast, ToastContainer } from "react-toastify";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const CampaignStatusDashboard = () => {
  const [campaignStatusData, setCampaignStatusData] = useState()
  const [openModal, setOpenModal] = useState(false);
  const authtoken = localStorage.getItem("token");

  useEffect(() => {
    const fetchCampaignStatusAPI = async () => {
      try {
        const response = await fetchCampaignStatus(authtoken);
        setCampaignStatusData(response?.data);
      } catch (error) {
        toast.error("Failed to fetch Campaign Status data");
      }
    };

    fetchCampaignStatusAPI();
  }, [authtoken]);

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={4}>
          <Card style={{ height: '120px', width: '265px', marginRight: '5px' }}>
            <CardContent>
              <Typography variant="h6" color="black">
                {campaignStatusData}
              </Typography>
              <Typography
                variant="body1"
                style={{ marginTop: '30px', display: 'flex', alignItems: 'center', cursor: 'pointer' }}
                onClick={() => setOpenModal(true)}
              >
                Active Campaign
                {openModal ? (
          <KeyboardArrowDownIcon
            style={{ marginLeft: '8px', fontSize: '1.2rem' }}
          />
        ) : (
          <KeyboardArrowRightIcon
            style={{ marginLeft: '8px', fontSize: '1.2rem' }}
          />
        )}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <Dialog
        open={openModal}
        onClose={() => setOpenModal(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle
        style={{ top:-2,marginBottom:'-6px' }}
        >
          Active Campaign Details
          <IconButton
            style={{ position: 'absolute', right: 8, top: 8 }}
            onClick={() => setOpenModal(false)}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <ActiveCamapaign />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default CampaignStatusDashboard;
