import WoocommerceDashboard from "../Components/WoocommerceDashboard";

export const endpoint = {
  LOGIN: "login",
  REGISTER: "register",
  GETCUTOMERDATA: "customers/",
  SORTEDCUTOMERDATA: "customers/sort",
  UPDATEPREMIUM: "update-premium",
  CREATEAISENSYUSER: "createAiSensyUser",
  FETCHALLAGENTS: "fetchAllAgents",
  UPDATEAGENTSTATUS: "updateAgentStatus",
  GETREFERRALDATA: "referrals",
  ACTVATEDUSERS: "activatedUsers",
  GETWOOCOMMERCECONFIGDATA: "wooCommerceConfigs",
  GetSHOPIFYDATA: "shopifyConfigs",
  GetSHOPIFYWEBHOOKS: "shopifyWebhooks",
  GetSMSDATA: "smsconfig",
  REMOVECONFIG:'remove-config',
  WALLETBALANCE:'wallet-balance',
  CURRENCY:'currency',
  WALOGS:'walogs',
  CAMPAIGNSTATUS:"campaign-status",
  ACTIVECAMPAIGNDETAILS:"active-Campaign",
  STOPCAMPAIGN:"stopCampaign",
  LASTWORKFLOWINICIATEDTIMESTAMP:"last-Workflow-initiated",
  NEWACCOUNTDETAILS:"new-account-created",
  SHOPIFYMESSAGESCOUNT:"shopify-messages-count",
  WOOCOMMERCEMESSAGESCOUNT:"woocommerce-messages-count",
};
