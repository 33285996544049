import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import * as React from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { fetchSMSData } from "../Services/index";
import { useState } from "react";
import { Box, Button, Modal } from "@mui/material";


export default function DataGridSMSConfig() {
    const authtoken = localStorage.getItem("token");
    const [smsData, setSmsData] = useState([]);
    const [pagination, setPagination] = useState({
        page: 0,
        limit: 10,
        totalRows: 1,
    });

    const [search] = useState("");
    const [loading, setLoading] = useState(true);

    React.useEffect(() => {
        const getSmsData = async () => {
            setLoading(true);
            fetchSMSData(authtoken, pagination.page, pagination.limit, search)
                .then((response) => {
                    setSmsData([...response?.data?.docs]);
                    setPagination({
                        ...pagination,
                        totalRows: response?.data?.total,
                    });
                })
                .catch(() => {
                    toast.error("Failed to fetch SMS data");
                })
                .finally(() => {
                    setLoading(false);
                });
        };

        getSmsData();
    }, [search, authtoken, pagination.limit, pagination.page]);


    const columns = [
        {
            field: "userName",
            headerName: "Name",
            width: 200,
            hide: false,
            renderCell: (params: any) => typeof params?.row?.userName === 'object'
                ? JSON.stringify(params?.row?.userName)
                : params?.row?.userName ?? "NA",
        },
        {
            field: "userEmail",
            headerName: "Email Id",
            width: 200,
            hide: false,
            renderCell: (params: any) => typeof params?.row?.userEmail === 'object'
                ? JSON.stringify(params?.row?.userEmail)
                : params?.row?.userEmail ?? "NA",
        },
        {
            field: "userMobile",
            headerName: "Phone Number",
            width: 200,
            hide: false,
            renderCell: (params: any) => typeof params?.row?.userMobile === 'object'
                ? JSON.stringify(params?.row?.userMobile)
                : params?.row?.userMobile ?? "NA",
        },
        {
            field: "userId",
            headerName: "User Id",
            width: 200,
            hide: false,
            renderCell: (params: any) => typeof params?.row?.userId === 'object'
                ? JSON.stringify(params?.row?.userId)
                : params?.row?.userId ?? "--",
        },
        {
            field: "userFacebookId",
            headerName: "Facebook Id",
            width: 200,
            hide: false,
            renderCell: (params: any) => typeof params?.row?.userFacebookId === 'object'
                ? JSON.stringify(params?.row?.userFacebookId)
                : params?.row?.userFacebookId ?? "NA",
        },
        {
            field: "selectedPlan",
            headerName: "Plan selected",
            width: 140,
            hide: false,
            renderCell: (params: any) => typeof params?.row?.selectedPlan === 'object'
                ? JSON.stringify(params?.row?.selectedPlan)
                : params?.row?.selectedPlan ?? "NA",
        },
        {
            field: "country",
            headerName: "Country",
            width: 120,
            hide: false,
            renderCell: (params: any) => typeof params?.row?.country === 'object'
                ? JSON.stringify(params?.row?.country)
                : params?.row?.country ?? "NA",
        },
        {
            field: "registrationId",
            headerName: "Registration Id",
            width: 200,
            hide: false,
            renderCell: (params: any) => typeof params?.row?.registrationId === 'object'
                ? JSON.stringify(params?.row?.registrationId)
                : params?.row?.registrationId ?? "NA",
        },
        {
            field: "urnId",
            headerName: "URN Id",
            width: 200,
            hide: false,
            renderCell: (params: any) => typeof params?.row?.urnId === 'object'
                ? JSON.stringify(params?.row?.urnId)
                : params?.row?.urnId ?? "NA",
        },
        {
            field: "apiKey",
            headerName: "API Key",
            width: 200,
            hide: false,
            renderCell: (params: any) => typeof params?.row?.apiKey === 'object'
                ? JSON.stringify(params?.row?.apiKey)
                : params?.row?.apiKey ?? "NA",
        },
        {
            field: "clientId",
            headerName: "Client Id",
            width: 200,
            hide: false,
            renderCell: (params: any) => typeof params?.row?.clientId === 'object'
                ? JSON.stringify(params?.row?.clientId)
                : params?.row?.clientId ?? "NA",
        },
        {
            field: "smsHeader",
            headerName: "SMS Header",
            width: 160,
            hide: false,
            renderCell: (params: any) => typeof params?.row?.smsHeader === 'object'
                ? JSON.stringify(params?.row?.smsHeader)
                : params?.row?.smsHeader ?? "NA",
        },
        {
            field: "templateId",
            headerName: "Template Id",
            width: 200,
            hide: false,
            renderCell: (params: any) => typeof params?.row?.templateId === 'object'
                ? JSON.stringify(params?.row?.templateId)
                : params?.row?.templateId ?? "NA",
        },
        {
            field: "templateValue",
            headerName: "Template Value",
            width: 180,
            hide: false,
            renderCell: (params: any) => typeof params?.row?.templateValue === 'object'
                ? JSON.stringify(params?.row?.templateValue)
                : params?.row?.templateValue ?? "NA",
        },
        {
            field: "fileURL",
            headerName: "File URL",
            width: 160,
            hide: false,
            renderCell: (params: any) => typeof params?.row?.fileURL === 'object'
                ? JSON.stringify(params?.row?.fileURL)
                : params?.row?.fileURL ?? "NA",
        },
        {
            field: "status",
            headerName: "Status",
            width: 100,
            hide: false,
            renderCell: (params: any) => typeof params?.row?.status === 'object'
                ? JSON.stringify(params?.row?.status)
                : params?.row?.status ?? "NA",
        },
        {
            field: "smsProvider",
            headerName: "SMS Provider",
            width: 100,
            hide: false,
            renderCell: (params: any) => typeof params?.row?.smsProvider === 'object'
                ? JSON.stringify(params?.row?.smsProvider)
                : params?.row?.smsProvider ?? "NA",
        },
        {
            field: "businessName",
            headerName: "Business Name",
            width: 160,
            hide: false,
            renderCell: (params: any) => typeof params?.row?.businessName === 'object'
                ? JSON.stringify(params?.row?.businessName)
                : params?.row?.businessName ?? "NA",
        },
        {
            field: "businessRegistrationID",
            headerName: "Business Registration Id",
            width: 200,
            hide: false,
            renderCell: (params: any) => typeof params?.row?.businessRegistrationID === 'object'
                ? JSON.stringify(params?.row?.businessRegistrationID)
                : params?.row?.businessRegistrationID ?? "NA",
        },
        {
            field: "emailID",
            headerName: "Email Id",
            width: 160,
            hide: false,
            renderCell: (params: any) => typeof params?.row?.emailID === 'object'
                ? JSON.stringify(params?.row?.emailID)
                : params?.row?.emailID ?? "NA",
        },
        {
            field: "phoneNumber",
            headerName: "Phone Number",
            width: 120,
            hide: false,
            renderCell: (params: any) => typeof params?.row?.phoneNumber === 'object'
                ? JSON.stringify(params?.row?.phoneNumber)
                : params?.row?.phoneNumber ?? "NA",
        },
        {
            field: "reason",
            headerName: "Reason",
            width: 200,
            hide: false,
            renderCell: (params: any) => typeof params?.row?.reason === 'object'
                ? JSON.stringify(params?.row?.reason)
                : params?.row?.reason ?? "NA",
        },
    ];
    

    return (
        <div style={{ height: 550, width: "100%" }}>
            <DataGrid
                rows={smsData}
                columns={columns}
                getRowId={(row: any) => row._id}
                loading={loading}
                slots={{ toolbar: GridToolbar }}
                disableColumnFilter
                rowCount={pagination.totalRows}
                pageSizeOptions={[10, 20, 25, 50, 75, 100]}
                paginationMode="server"
                paginationModel={{
                    page: pagination.page,
                    pageSize: pagination.limit,
                }}
                onPaginationModelChange={(params) => {
                    setPagination({
                        ...pagination,
                        page: params.page,
                        limit: params.pageSize,
                    });
                }}
                slotProps={{
                    toolbar: {
                        csvOptions: {
                            allColumns: true,
                            fields: [
                                "userId",
                                "userName",
                                "userEmail",
                                "userMobile",
                                "userFacebookId",
                                "selectedPlan",
                                "country",
                                "registrationId",
                                "urnId",
                                "apiKey",
                                "clientId",
                                "smsHeader",
                                "templateId",
                                "templateValue",
                                "fileURL",
                                "status",
                                "smsProvider",
                                "businessName",
                                "businessRegistrationID",
                                "emailID",
                                "phoneNumber",
                                "reason",
                            ],
                        },
                    },
                }}
            />
            <ToastContainer />
        </div>
    );
}
